import React, { useState } from "react";
import { Button } from "reactstrap";
import UploadProductInfo from "./uploadInfo";

const AddProductButton = ({ getAll }) => {
  const [show, setShow] = useState(false);

  const handleModalShow = () => {
    setShow(true);
  };

  const handleToggle = () => {
    setShow(!show);
  };
  return (
    <>
      <UploadProductInfo
        getAll={getAll}
        show={show}
        setShow={setShow}
        handleModalShow={handleModalShow}
        handleToggle={handleToggle}
        isUpdated={false}
      />
      <Button color="success" onClick={handleModalShow}>
        Mahsulot qo'shish
      </Button>
    </>
  );
};

export default AddProductButton;
