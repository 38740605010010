import { Container } from "reactstrap";

const Header = () => {
  return (
    <>
      <div className="header bg-gradient-info pb-6 ">
        <Container fluid></Container>
      </div>
    </>
  );
};

export default Header;
