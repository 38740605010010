import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { getUserProfile, logout } from "../../network/ApiAxios";

const AdminNavbar = (props) => {
  const [user, setUser] = useState({});

  const logOut = () => {
    logout();
    props.history.push("/auth/login");
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await getUserProfile();
      if (data && data.success && data.data) setUser(data.data);
    };
    getUser();
  }, []);

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <h1 className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block">
            {props.brandText}
          </h1>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={require("../../assets/img/theme/team-1-800x800.jpg")}
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {user.email}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Mening profilim</span>
                </DropdownItem>
                {user.role === "superAdmin" ? (
                  <DropdownItem to="/admin/settings" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Sozlamalar</span>
                  </DropdownItem>
                ) : (
                  ""
                )}
                <DropdownItem divider />
                <DropdownItem onClick={logOut}>
                  <i className="ni ni-user-run" />
                  <span>Chiqish</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
