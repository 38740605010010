import React from "react";
import Header from "../../components/Headers/Header";
import OrderTable from "../../components/tables/OrderTable";

const Orders = () => {
  return (
    <div>
      <Header />
      <OrderTable />
    </div>
  );
};

export default Orders;
