import React, { useState } from "react";
import { Button } from "reactstrap";
import UploadProductInfo from "./uploadInfo";

const UpdateProductButton = ({ getAll, isUpdated, product }) => {
  const [show, setShow] = useState(false);

  const handleModalShow = () => {
    setShow(true);
  };

  const handleToggle = () => {
    setShow(!show);
  };
  return (
    <>
      <UploadProductInfo
        show={show}
        setShow={setShow}
        handleModalShow={handleModalShow}
        handleToggle={handleToggle}
        isUpdated={isUpdated}
        product={product}
        getAll={getAll}
      />
      <Button color="info" className="btn-sm" onClick={handleModalShow}>
        E
      </Button>
    </>
  );
};

export default UpdateProductButton;
