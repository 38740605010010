import PublicNavbar from "components/Navbars/PublicNavbar";
import React from "react";
import {useLocation} from "react-router-dom";
import {Card, CardBody, Col, Container, Row} from "reactstrap";

const Privacy = () => {
	const location = useLocation();
	const mainContent = React.useRef(null);
	React.useEffect(() => {
		document.body.classList.add("bg-default");
		return () => {
			document.body.classList.remove("bg-default");
		};
	}, []);

	React.useEffect(() => {
		document.documentElement.scrollTop = 0;
		document.scrollingElement.scrollTop = 0;
		mainContent.current.scrollTop = 0;
	}, [location]);
	return (
		<div className="main-content" ref={mainContent}>
			<PublicNavbar />
			<div className="header bg-gradient-info py-7 py-lg-8">
				<div className="separator separator-bottom separator-skew zindex-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						version="1.1"
						viewBox="0 0 2560 100"
						x="0"
						y="0"
					>
						<polygon className="fill-default" points="2560 0 2560 100 0 100" />
					</svg>
				</div>
			</div>
			<Container className="mt--8 pb-5 h-3">
				<Row className="justify-content-center">
					<Col lg="12" md="12">
						<Card className="bg-secondary shadow border-0 p-4">
							<h1 className="text-center">Maxfiylik siyosati</h1>
							<CardBody>
								<p>
									Shaxsiy ma’lumotlarga nisbatan ushbu Maxfiylik siyosati
									(bundan keyin – Maxfiylik siyosati) Internetda
									joylashtirilgan: Google Play storeda mavjud (bundan keyin -
									Mobile ilova) mobil ilovada joylashtirilgan barcha
									ma’lumotlarga qo’llaniladi.
								</p>
								<p>
									Mobil ilovadan foydalanish Foydalanuvchi tomonidan uning
									Siyosatiga va unda ko’rsatilgan shaxsiy ma’lumotlarini qayta
									ishlash shartlariga so'zsiz roziligini anglatadi; ushbu
									shartlarga rozi bo’lmaganda, Foydalanuvchi Mobil ilovadan
									foydalanishni rad qilishi kerak.
								</p>
								<h3 className="text-center">1.UMUMIY QOIDALAR</h3>
								<p>
									1.1. Ushbu Siyosat doirasida Foydalanuvchining shaxsiy
									ma’lumotlari quyidagilarni anglatadi:
								</p>
								<p>
									1.1.1. Mobil ilovadan foydalanganda Foydalanuvchi o’zi
									to’g’risida taqdim etgan har qanday shaxsiy ma’lumotlar, shu
									jumladan foydalanuvchining shaxsiy ma’lumotlari.
								</p>
								<p>
									1.1.2. Foydalanuvchi qurilmasida o’rnatilgan dasturiy
									ta’minotdan foydalangan holda Mobil ilovaga avtomatik ravishda
									uzatiladigan ma’lumotlar, shu jumladan IP-manzili,
									cookie-fayllari ma’lumotlari, foydalanuvchi brauzeri (yoki
									boshqa dastur) haqidagi ma’lumotlar. Foydalanuvchi tomonidan
									foydalaniladigan asbob-uskunalar va dasturiy ta’minot
									xususiyatlari, xizmatlarga kirish sanasi va vaqti, so’ralgan
									sahifalarning manzillari va boshqa shunga o’xshash
									ma’lumotlar.{" "}
								</p>
								<p>
									1.2. Ushbu Maxfiylik siyosati faqat “Xalqlar do’stligi
									(shopping)” mobil ilovasiga tegishli. Mobil ilova ichidagi
									havolalarni bosishi mumkin bo’lgan uchinchi tomon saytlarini
									nazorat qilmaydi va javobgar bo’lmaydi.
								</p>
								<h3 className="text-center">
									2.FOYDALANUVCHILARNING SHAXSIY MA’LUMOTLARI{" "}
								</h3>
								<p>
									2.1. Mobil ilova qonunda ko’rsatilgan muddatda shaxsiy
									ma’lumotlarni majburiy saqlashni nazarda tutmagan bo’lsa,
									faqat xizmatlar taqdim etish yoki Foydalanuvchi bilan tuzilgan
									shartnomalar va shartnomalarni bajarish uchun zarur bo’lgan
									shaxsiy ma’lumotlarni to’playdi va saqlaydi.{" "}
								</p>
								<p>
									2.2. Mobil ilova foydalanuvchining shaxsiy ma’lumotlarini
									quyidagi maqsadlarda qayta ishlaydi:{" "}
								</p>
								<p>
									2.2.1. Foydalanuvchi bilan aloqa o’rnatish, jumladan
									bildirishnomalarni yuborish, Mobil ilovadan foydalanish,
									xizmatlarni taqdim etish, foydalanuvchi so’rovlari va
									arizalarini ko’rib chiqish.{" "}
								</p>
								<p>
									2.2.2. Foydalanuvchi tomonidan taqdim etilgan shaxsiy
									ma’lumotlarning aniqligi va to’liqligini tasdiqlash.{" "}
								</p>
								<p>
									2.2.3. Mobil ilovadan foydalanish bilan bog’liq muammolar
									yuzaga kelganda, foydalanuvchiga texnik yordamni taqdim etish.
								</p>
								<h3 className="text-center">
									3.FOYDALANUVCHILARNING SHAXSIY MA’LUMOTLARINI ISHLAB CHIQARISH
									ShARTLARI VA ULARNI UChINCHI TOMONLARGA O’TKAZISh
								</h3>
								<p>
									3.1. Foydalanuvchining shaxsiy ma’lumotlariga nisbatan uning
									maxfiyligi saqlanadi, foydalanuvchi cheksiz ko’p odamlarga
									kirish uchun ixtiyoriy ravishda o’zi to’g’risida ma’lumot
									bergan hollar bundan mustasno. Muayyan xizmatlardan
									foydalanganda, foydalanuvchi o’zining shaxsiy ma’lumotlarining
									ma’lum qismi ommaga ochiq bo’lishiga rozi bo’ladi.{" "}
								</p>
								<p>
									3.2. Mobil ilova ma’muriyati quyidagi holatlarda
									foydalanuvchining shaxsiy ma’lumotlarini uchinchi shaxslarga
									topshirishga haqlidir:{" "}
								</p>
								<p>3.2.1. Foydalanuvchi bunday harakatlarga rozi bo’lganda.</p>
								<p>
									3.2.2. O’tkazma Foydalanuvchi tomonidan ma’lum bir xizmatdan
									foydalanish uchun yoki Foydalanuvchi bilan maxsus shartnoma
									yoki shartnomani bajarish uchun kerak.{" "}
								</p>
								<p>
									3.2.3. O’tkazma O’zbekiston Respublikasi qonun hujjatlarida
									qonunda belgilangan tartibda taqdim etiladi.
								</p>
								<p>
									3.3. A’zoning shaxsiy ma’lumotlarini qayta ishlash hech qanday
									qonuniy vositalar bilan, shu jumladan avtomatlashtirish
									vositalaridan foydalangan holda yoki bunday vositalardan
									foydalanmasdan shaxsiy ma’lumot tizimlarida ham amalga
									oshiriladi.{" "}
								</p>
								<p>
									3.4. Mobil ilova ma’muriyati foydalanuvchining shaxsiy
									ma’lumotlarini yo’q qilish, o’zgartirish, blokirovka qilish,
									nusxalash, tarqatish, shuningdek, uchinchi shaxslarning boshqa
									noqonuniy harakatlaridan himoya qilish uchun zarur tashkiliy
									va texnik choralarni ko’radi.
								</p>
								<h3 className="text-center">4.TOMONLARNING MAJBURIYATLARI.</h3>
								<p>4.1. Foydalanuvchining majburiyatlari: </p>
								<p>
									4.1.1. Mobil ilovadan foydalanish uchun zarur bo’lgan shaxsiy
									ma’lumotlar to’g’risida ma’lumot berish.{" "}
								</p>
								<p>
									4.1.2. Ushbu ma’lumotlar o’zgarganda shaxsiy ma’lumotlar
									to’g’risidagi ma’lumotlarni yangilash, to’ldirish.{" "}
								</p>
								<p>
									4.2.Mobil ilova ma’muriyati majburiyatlari: Olingan
									ma’lumotlardan faqat ushbu Maxfiylik siyosatida ko’rsatilgan
									maqsadlarda foydalanish.{" "}
								</p>
								<p>
									4.2.2. Maxfiy ma’lumotning maxfiy ravishda saqlanishini
									ta’minlash, foydalanuvchining yozma ruxsatisiz uni oshkor
									qilmaslik, shuningdek, ushbu Maxfiylik siyosatida ko’zda
									tutilgan hollar bundan mustasno, foydalanuvchining uzatilgan
									shaxsiy ma’lumotlarini sotish, almashish, nashr etish va
									boshqa yo’llar bilan oshkor etmaslik.{" "}
								</p>
								<p>
									4.2.3. Amaldagi biznes aylanmasida ushbu turdagi ma’lumotlarni
									himoya qilish uchun foydalaniladigan protseduraga muvofiq
									foydalanuvchi shaxsiy ma’lumotlarining maxfiyligini himoya
									qilish choralarini ko’rish.{" "}
								</p>
								<p>
									4.2.4. Noto’g’ri shaxsiy ma’lumotlar yoki noqonuniy
									xatti-harakatlar aniqlangan taqdirda, foydalanuvchi yoki uning
									qonuniy vakili yoki vakolatli organ bilan bog’langan yoki
									so’ralgan paytdan boshlab tegishli foydalanuvchi bilan bog’liq
									shaxsiy ma’lumotlarni tekshirish muddati davomida tekshirish.{" "}
								</p>
								<h3 className="text-center">5.TOMONLARNING JAVOBGARLIGI</h3>
								<p>
									5.1. Mobil ilova ma’muriyati o’z majburiyatlarini bajarmagan
									holda, Foydalanuvchi tomonidan shaxsiy ma’lumotlardan
									suiiste’mol qilinishi natijasida yetkazilgan zararlar uchun
									O’zbekiston Respublikasi qonunchiligiga muvofiq javobgar
									bo’ladi.{" "}
								</p>
								<p>
									5.2. Maxfiy ma’lumot yo’qolgan yoki oshkor qilingan taqdirda,
									Mobil ilova ma’muriyati, agar ushbu maxfiy ma’lumot mavjud
									bo’lsa, javobgar bo’lmaydi:{" "}
								</p>
								<p>
									5.2.1. U yo’qolishi yoki oshkor etilishidan oldin ommaviy
									bo’ldi.
								</p>
								<p>
									5.2.2. U uchinchi tomon tomonidan Mobil ilova ma’muriyati
									tomonidan qabul qilinmaguncha olingan.{" "}
								</p>
								<p>5.2.3. U Foydalanuvchi roziligi bilan oshkor qilindi. </p>
								<h3 className="text-center">6.TORTISHUVLARNI HAL QILISH </h3>
								<p>
									6.1. Mobil ilova foydalanuvchisi va Mobil ilova ma’muriyati
									o’rtasidagi munosabatlardan kelib chiqadigan nizolar bo’yicha
									da’vo arizasi bilan sudga murojaat qilishdan oldin, da’vo
									arizasini berish kerak (nizoni ixtiyoriy hal qilish bo’yicha
									yozma taklif).{" "}
								</p>
								<p>
									6.2. Da’voni oluvchi da’voni olgan kundan boshlab 30 (o’ttiz)
									kalendar kuni ichida da’voni ko’rib chiqish natijalari
									to’g’risida yozma ravishda xabardor qiladi.{" "}
								</p>
								<p>
									6.3. Agar kelishuvga erishilmasa, nizo O’zbekiston
									Respublikasining amaldagi qonunchiligiga muvofiq sudga
									yuboriladi.{" "}
								</p>
								<p>
									6.4. O’zbekiston Respublikasining amaldagi qonunchiligi ushbu
									Maxfiylik siyosatiga va foydalanuvchi va Mobil ilova
									ma’muriyati o’rtasidagi munosabatlarga nisbatan qo’llaniladi.
								</p>
								<h3 className="text-center">7.QO’ShIMChA ShARTLAR </h3>
								<p>
									7.1. Mobil ilova ma’muriyati foydalanuvchining roziligisiz
									ushbu Maxfiylik siyosatiga o’zgartirishlar kiritishga
									haqlidir.{" "}
								</p>
								<p>
									7.2. Yangi Maxfiylik siyosati Saytda joylashtirilgan paytdan
									boshlab kuchga kiradi, agar Maxfiylik siyosatining yangi
									versiyasida boshqacha qoida nazarda tutilmagan bo’lsa.
								</p>
								<p>
									7.3. Ushbu Maxfiylik siyosati bo’yicha barcha takliflar yoki
									savollar
									<a href="mailto:abdulkhaev96@gmail.com">
										&nbsp;abdulkhaev96@gmail.com&nbsp;
									</a>
									elektron manziliga yuborilishi kerak.
								</p>
								<p>
									7.4. Mavjud Maxfiylik siyosati
									<a href="https://xalqlar-dostligi.uz/privacy">
										&nbsp;xalqlar-dostligi.uz/privacy&nbsp;
									</a>
								</p>
								<p>
									Ushbu hujjat oxirgi marta 2024-yil 20-iyul kuni yangilandi.
								</p>
							</CardBody>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Privacy;
