import Index from "./views/Index.js";
import Login from "./views/examples/Login.js";
import Register from "./views/examples/Register";

import MyProfile from "./views/examples/MyProfile.js";
import Settings from "./views/examples/Settings.js";
import OneProductInfo from "./views/examples/OneProductInfo.js";
import Customers from "./views/examples/Customers.js";
import Orders from "./views/examples/Orders.js";
import OneCustomer from "./views/examples/OneCustomer.js";
import OneOrder from "./views/examples/OneOrder.js";

var routes = [
	{
		path: "/index",
		name: "Mahsulotlar",
		icon: "ni ni-folder-17 text-primary",
		component: Index,
		layout: "/admin",
	},
	{
		path: "/customers",
		name: "Xaridorlar",
		icon: "ni ni-single-02 text-primary",
		component: Customers,
		layout: "/admin",
	},
	{
		path: "/customer/:id",
		name: "Xaridor haqida",
		icon: "ni ni-single-02 text-primary",
		component: OneCustomer,
		layout: "/admin",
		api: true,
	},
	{
		path: "/order/:id",
		name: "Buyurtma haqida",
		icon: "ni ni-single-02 text-primary",
		component: OneOrder,
		layout: "/admin",
		api: true,
	},
	{
		path: "/orders",
		name: "Buyurtmalar",
		icon: "ni ni-shop text-primary",
		component: Orders,
		layout: "/admin",
	},
	{
		path: "/product/:id",
		name: "Mahsulot haqida",
		icon: "ni ni-check-bold text-primary",
		component: OneProductInfo,
		layout: "/admin",
		api: true,
	},
	{
		path: "/user-profile",
		name: "Mening profilim",
		icon: "ni ni-check-bold text-primary",
		component: MyProfile,
		layout: "/admin",
		api: true,
	},
	{
		path: "/settings",
		name: "Sozlamalar",
		icon: "ni ni-settings-gear-65 text-primary",
		component: Settings,
		layout: "/admin",
		api: true,
	},
	{
		path: "/login",
		name: "Kirish",
		icon: "ni ni-tv-2 text-primary",
		component: Login,
		layout: "/auth",
		api: true,
	},
	{
		path: "/register",
		name: "Ro'yxatdan o'tish",
		icon: "ni ni-circle-08 text-pink",
		component: Register,
		layout: "/auth",
		api: true,
	},
];
export default routes;
