import { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { Container } from "reactstrap";
import {
  getUserProfile,
  updateProfile,
  updatePassword,
} from "../../network/ApiAxios";
import { toast } from "react-toastify";

const MyProfile = () => {
  const [user, setUser] = useState({});
  const [dataEdit, setDataEdit] = useState(false);
  const [formData, setFormData] = useState({
    name: user.name,
    currentPassword: "",
    newPassword: "",
    reNewPassword: "",
  });
  const { name, currentPassword, newPassword, reNewPassword } = formData;

  const onSubmitEdit = async (e) => {
    e.preventDefault();

    const data = await updateProfile({ name });
    if (data && data.success && data.data) {
      setUser(data.data);
      toast.success("Ma'lumot o'zgartirildi!");
    } else {
      setFormData({
        name: user.name,
        currentPassword: "",
        newPassword: "",
        reNewPassword: "",
      });
    }

    setDataEdit(false);
  };

  const onSubmitEditPassword = async (e) => {
    e.preventDefault();

    if (newPassword === reNewPassword) {
      const data = await updatePassword({
        currentPassword,
        newPassword,
      });
      if (data && data.success) toast.success(data.message);
      setFormData({
        name: user.name,
        currentPassword: "",
        newPassword: "",
        reNewPassword: "",
      });
    } else {
      toast.error("Yangi parollar bir xil emas!");
    }
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await getUserProfile();
      if (data && data.success && data.data) setUser(data.data);
    };
    getUser();
  }, []);

  useEffect(() => {
    setFormData({
      name: user.name,
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
    });
  }, [user]);

  return (
    <div>
      <Header />
      <Container>
        <section>
          <div className="row mt-5">
            <div className="col-5">
              <h1 className="mb-3">Shaxsiy ma'lumotlar</h1>
              <form id="edit-p-i" onSubmit={onSubmitEdit}>
                <div className="mt-2 d-flex align-items-center">
                  <label className="form-label w-50" htmlFor="email-p">
                    <b>Elektron pochta:</b>
                  </label>
                  <input
                    className={`form-control ${dataEdit ? "" : "border-0"}`}
                    disabled
                    type="email"
                    defaultValue={user.email}
                    id="email-p"
                  />
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <label className="form-label w-50" htmlFor="phone-p">
                    <b>Telefon raqam:</b>
                  </label>
                  <input
                    className={`form-control ${dataEdit ? "" : "border-0"}`}
                    disabled
                    type="text"
                    defaultValue={user.phone}
                    id="phone-p"
                  />
                </div>
                <div className="mt-2 d-flex align-items-center">
                  <label className="form-label w-50" htmlFor="name-p">
                    <b>Ism Familya:</b>
                  </label>
                  <input
                    className={`form-control ${dataEdit ? "" : "border-0"}`}
                    disabled={!dataEdit}
                    type="text"
                    value={name || ""}
                    onChange={onChange}
                    id="name-p"
                    name="name"
                  />
                </div>
                {dataEdit ? (
                  <div className="mt-3 btn-group w-100">
                    <button
                      className="btn btn-success"
                      type="submit"
                      form="edit-p-i"
                    >
                      Saqlash
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setDataEdit(false);
                        setFormData({
                          name: user.name,
                          currentPassword: "",
                          newPassword: "",
                          reNewPassword: "",
                        });
                      }}
                      className="btn btn-danger"
                    >
                      Bekor qilish
                    </button>
                  </div>
                ) : (
                  <button
                    className="btn btn-primary my-3 w-100"
                    onClick={() => setDataEdit(true)}
                    type="button"
                  >
                    O'zgartirish
                  </button>
                )}
              </form>
            </div>
            <div className="col-5 offset-1">
              <h1 className="mb-3">Parolni o'zgartirish</h1>
              <form id="update-password" onSubmit={onSubmitEditPassword}>
                <div className="mt-2">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Eski parolni kiriting"
                    value={currentPassword}
                    name="currentPassword"
                    onChange={onChange}
                    minLength={6}
                    required
                  />
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Yangi parolni kiriting"
                    value={newPassword}
                    name="newPassword"
                    onChange={onChange}
                    minLength={6}
                    required
                  />
                </div>
                <div className="mt-2">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Yangi parolni qaytadan kiriting"
                    value={reNewPassword}
                    name="reNewPassword"
                    onChange={onChange}
                    minLength={6}
                    required
                  />
                </div>
                <div className="my-3">
                  <button
                    type="submit"
                    form="update-password"
                    className="btn btn-primary w-100"
                  >
                    O'zgartirish
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </Container>
    </div>
  );
};

export default MyProfile;
