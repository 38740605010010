export const categoryList = [
  { label: "Ichimliklar", value: "drink" },
  { label: "Qandolat", value: "sweet" },
  { label: "Pechenye-vafli", value: "cookie" },
  { label: "Pishiriqlar", value: "cake" },
  { label: "Murabbo", value: "jam" },
  { label: "Sut mahsulotlari", value: "milk" },
  { label: "Oziq ovqat mahsulotlari", value: "food" },
  { label: "Quruq mevalar", value: "dried_fruit" },
  { label: "Kanservalar", value: "canned_food" },
  { label: "Makaron-rolton", value: "noodle" },
  { label: "Don maxsulotlar", value: "corn" },
  { label: "Ziravorlar", value: "spice" },
  { label: "Choy-Zavarka", value: "tea" },
  { label: "Go'sht mahsulotlari", value: "meat" },
  { label: "Mol go'shti", value: "beef" },
  { label: "Qo'y go'shti", value: "mutton" },
  { label: "Tovuq go'shti", value: "chicken" },
  { label: "Kolbasa mahsulotlari", value: "sausage" },
  { label: "Dengiz mahsulotlari", value: "sea" },
  { label: "Baliq go'shti", value: "fish" },
  { label: "Non mahsulotlari", value: "bread" },
  { label: "Parfumera mahsulotlari", value: "parfume" },
  { label: "Texnikalar", value: "tech" },
  { label: "Xo'jalik buyumlari", value: "household" },
];
