import React from "react";
import Header from "components/Headers/Header";
import CustomerTable from "../../components/tables/CustomerTable";

const Customers = () => {
  return (
    <div>
      <Header />
      <CustomerTable />
    </div>
  );
};

export default Customers;
