import React, { useState } from "react";
import { RiUploadCloud2Fill } from "react-icons/ri";
import { Button, Col, Input, Label, Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";

import { createProduct, updateProduct } from "../../network/ApiAxios";
import { categoryList } from "constants/category";

const UploadProductInfo = ({
  getAll,
  show,
  handleToggle,
  isUpdated,
  product,
}) => {
  const [files, setFile] = useState("");
  const [productName, setProductName] = useState(
    product?.productName ? product.productName : ""
  );
  const [price, setPrice] = useState(product?.price ? product.price : "");
  const [sellingPrice, setSellingPrice] = useState(
    product?.sellingPrice ? product.sellingPrice : ""
  );
  const [expirationDate, setExpirationDate] = useState(
    product?.expirationDate ? product.expirationDate : ""
  );
  const [description, setDescription] = useState(
    product?.description ? product.description : ""
  );
  const [barCode, setBarCode] = useState(
    product?.barCode ? product.barCode : ""
  );
  const [amountProduct, setAmountProduct] = useState(
    product?.amountProduct ? product.amountProduct : ""
  );
  const [unitOfAmount, setUnitOfAmount] = useState(
    product?.unitOfAmount ? product.unitOfAmount : ""
  );
  const [category, setCategory] = useState(
    product?.category ? product.category : ""
  );

  const [discount, setDiscount] = useState(
    product?.discount ? product.discount : ""
  );
  const [afterDiscountPrice, setAfterDiscountPrice] = useState(
    product?.afterDiscountPrice ? product.afterDiscountPrice : ""
  );

  const handleClick = async () => {
    const form = new FormData();
    form.append("imageProduct", files[0]);
    form.append("productName", productName);
    form.append("price", price);
    form.append("sellingPrice", sellingPrice);
    form.append("discount", discount);
    form.append("afterDiscountPrice", afterDiscountPrice);
    form.append("expirationDate", expirationDate);
    form.append("description", description);
    form.append("barCode", barCode);
    form.append("amountProduct", amountProduct);
    form.append("unitOfAmount", unitOfAmount);
    form.append("category", category);

    if (!isUpdated) {
      const data = await createProduct(form);
      if (data && data.success) {
        toast.success("Mahsulot qo'shildi!");
        setProductName("");
        setFile("");
        setPrice("");
        setSellingPrice("");
        setExpirationDate("");
        setBarCode("");
        setAmountProduct("");
        setUnitOfAmount("");
        setCategory("");
        setDescription("");
        setDiscount("");
        setAfterDiscountPrice("");
      }
    } else {
      const data = await updateProduct(product._id, form);
      if (data && data.success) toast.success("Mahsulot o'zgartirildi!");
    }
    handleToggle();
    getAll();
  };

  return (
    <div>
      <Modal isOpen={show} toggle={handleToggle} className="" size="lg">
        <ModalBody>
          {!isUpdated ? (
            <h1>Maxsulot ro'yxatga qo'shish</h1>
          ) : (
            <h1>Maxsulotni o'zgartirish</h1>
          )}

          <div style={{ display: "flex" }}>
            <div style={{ flex: "1" }}>
              <Col md="11">
                <Label for="productName" style={{ fontSize: "14px" }}>
                  Maxsulot nomi
                </Label>
                <Input
                  type="text"
                  id="productName"
                  value={productName}
                  onChange={(e) => setProductName(e.target.value)}
                />
              </Col>

              <Col md="11">
                <Label
                  for="category"
                  style={{ fontSize: "14px", marginTop: "20px" }}
                >
                  Kategoriya
                </Label>
                <Input
                  id="category"
                  type="select"
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value={""}>Kategoriyani tanlang</option>
                  {categoryList.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </Input>
              </Col>

              <Col md="11">
                <Label
                  for="expirationDate"
                  style={{ marginTop: "20px", fontSize: "14px" }}
                >
                  Yaroqlilik muddati
                </Label>
                <Input
                  type="date"
                  id="expirationDate"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                />
              </Col>

              <Col md="11">
                <Label
                  for="expirationDate"
                  style={{ marginTop: "20px", fontSize: "14px" }}
                >
                  Mahsulot haqida ko'proq yozing
                </Label>
                <Input
                  type="textarea"
                  id="description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Col>

              <Col md="11">
                <Label
                  for="imageProduct"
                  style={{ marginTop: "20px", fontSize: "14px" }}
                >
                  Maxsulot surati
                  <div
                    className="label mt-2"
                    style={{
                      width: "315px",
                      height: "140px",
                      border: "1px solid #BEBEBE",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                  >
                    {files[0] ? "" : <RiUploadCloud2Fill />}
                    <p style={{ fontSize: "16px", textAlign: "center" }}>
                      {files[0] ? files[0].name : "Maxsulot suratini yuklang"}
                    </p>
                  </div>
                </Label>
                <Input
                  onChange={(e) => setFile(e.target.files)}
                  type="file"
                  id="imageProduct"
                  className="d-none"
                />
              </Col>
            </div>
            <div style={{ flex: "1" }}>
              <Label for="price">Narxi</Label>
              <Input
                type="number"
                id="price"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <Label
                for="sellingPrice"
                style={{ marginTop: "20px", fontSize: "14px" }}
              >
                Sotuv narxi
              </Label>
              <Input
                type="number"
                id="sellingPrice"
                value={sellingPrice}
                onChange={(e) => {
                  setAfterDiscountPrice("");
                  setDiscount("");
                  setSellingPrice(e.target.value);
                }}
              />

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <Label
                    for="discount"
                    style={{ marginTop: "20px", fontSize: "14px" }}
                  >
                    Chegirma (% foiz)
                  </Label>
                  <Input
                    type="number"
                    id="discount"
                    disabled={sellingPrice === ""}
                    value={discount}
                    onChange={(e) => {
                      setAfterDiscountPrice(
                        Number(sellingPrice) -
                          (Number(sellingPrice) * Number(e.target.value)) / 100
                      );
                      setDiscount(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <Label
                    for="afterDiscountPrice"
                    style={{ marginTop: "20px", fontSize: "14px" }}
                  >
                    Chegirmadan so'ng narxi
                  </Label>
                  <Input
                    type="number"
                    id="afterDiscountPrice"
                    disabled={sellingPrice === ""}
                    value={afterDiscountPrice}
                    onChange={(e) => {
                      setDiscount(
                        100 - (Number(e.target.value) * 100) / sellingPrice
                      );
                      setAfterDiscountPrice(e.target.value);
                    }}
                  />
                </div>
              </div>

              <Label
                for="amountProduct"
                style={{ marginTop: "20px", fontSize: "14px" }}
              >
                Qancha bor
              </Label>
              <Input
                type="number"
                id="amountProduct"
                value={amountProduct}
                onChange={(e) => setAmountProduct(e.target.value)}
              />

              <Label
                for="unitOfAmount"
                style={{ marginTop: "20px", fontSize: "14px" }}
              >
                Miqdorining birligi
              </Label>
              <Input
                id="unitOfAmount"
                type="select"
                value={unitOfAmount}
                onChange={(e) => setUnitOfAmount(e.target.value)}
              >
                <option value={""}>Miqdor birligini tanlang</option>
                <option value={"kg"}>kg</option>
                <option value={"gr"}>gr</option>
                <option value={"dona"}>dona</option>
                <option value={"litr"}>litr</option>
              </Input>

              <Label
                for="barcode"
                style={{ marginTop: "20px", fontSize: "14px" }}
              >
                Bar code
              </Label>
              <Input
                type="text"
                value={barCode}
                onChange={(e) => setBarCode(e.target.value)}
              />
              <div
                style={{
                  marginTop: "30px",
                  display: "flex",
                  justifyContent: "right",
                }}
              >
                <Button
                  color="warning"
                  onClick={() => {
                    handleToggle();
                    setProductName("");
                    setFile("");
                    setPrice("");
                    setSellingPrice("");
                    setExpirationDate("");
                    setBarCode("");
                    setAmountProduct("");
                    setUnitOfAmount("");
                    setCategory("");
                  }}
                >
                  Bekor qilish
                </Button>
                <Button color="success" onClick={handleClick}>
                  {isUpdated ? "O'zgartirish" : "Qo'shish"}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UploadProductInfo;
