import React from "react";

const ProductPagination = ({ getAll, pageData }) => {
  const { currentPage, pageCount, nextPage } = pageData;
  return (
    <nav className="mt-3">
      <ul className="pagination pagination-lg justify-content-center">
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => {
              getAll(1);
            }}
          >
            1
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => {
              getAll(currentPage - 1);
            }}
          >
            avvalgi
          </button>
        </li>
        <li className="page-item active">
          <button disabled className="page-link">
            {currentPage}
          </button>
        </li>
        <li className={`page-item ${nextPage ? "" : "disabled"}`}>
          <button
            className="page-link"
            onClick={() => {
              getAll(nextPage);
            }}
          >
            keyingi
          </button>
        </li>
        <li
          className={`page-item ${pageCount === currentPage ? "disabled" : ""}`}
        >
          <button
            className="page-link"
            onClick={() => {
              getAll(pageCount);
            }}
          >
            {pageCount}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default ProductPagination;
