import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { useParams } from "react-router-dom";
import { getByIdProduct } from "../../network/ApiAxios";
import { Button, Col, Container, Row } from "reactstrap";

const OneProductInfo = (props) => {
  const { id } = useParams();
  const [product, setProduct] = useState("");

  useEffect(() => {
    const getProduct = async () => {
      const data = await getByIdProduct(id);
      if (data && data.data) {
        setProduct(data.data);
      } else {
        props.history.push("/");
      }
    };
    getProduct();
  }, [id, props.history]);

  return (
    <div>
      <Header />
      {product && (
        <Container className="mt-4">
          <Row>
            <Col md="6">
              <h1>
                Mahsulot nomi: <i>{product && product.productName}</i>
              </h1>
              <h1>Mahsulot haqida:</h1>
              <p>
                <i>{product && product?.description}</i>
              </p>
              <h1>
                Narxi: <i>{product && product.price}</i>
              </h1>
              <h1>
                Sotuv narxi: <i>{product && product.sellingPrice}</i>
              </h1>
              <h1>
                Chegirmadan so'ng narxi:{" "}
                <i>
                  {(product && product.afterDiscountPrice) ||
                    product.sellingPrice}
                </i>
              </h1>
              <h1>
                Chegirma foizi: <i>{(product && product.discount) || 0}</i>
              </h1>
              <h1>
                Yaqoqlik muddati: <i>{product && product.expirationDate}</i>
              </h1>
              <h1>
                Bazada qoldi:
                <i>
                  {product && product.amountProduct}{" "}
                  {product && product.unitOfAmount}
                </i>
              </h1>
              <h1>
                BarCode: <i>{product && product.barCode}</i>
              </h1>
              <Button
                color="success"
                onClick={() => props.history.push("/admin/index")}
              >
                Asosiy
              </Button>
              <Button
                color="success"
                onClick={() => props.history.push("/admin/" + product.category)}
              >
                Kategoriya
              </Button>
            </Col>
            <Col md="6">
              <img
                style={{ height: "500px" }}
                className="img-fluid img-thumbnail"
                src={`https://s3.timeweb.cloud/1a12bb9e-files-db/xalqlar-dustligi/${product.imageProduct}`}
                alt={product.productName}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default OneProductInfo;
