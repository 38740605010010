import Header from "../components/Headers/Header.js";
import MainTableProduct from "../components/tables/MainTableProduct.js";

import { Container } from "reactstrap";

const Index = () => {
  return (
    <>
      <Header />
      <Container>
        <MainTableProduct />
      </Container>
    </>
  );
};

export default Index;
