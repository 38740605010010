import axios from "axios";
import config from "../config";
import {toast} from "react-toastify";

const instance = axios.create({
	baseURL: config.WS_BASE_URL + "/api/v1/",
});

export const getAllProducts = async ({category, page, search, sort}) => {
	const apiKey = localStorage.getItem("apiKey");
	const categoryQuery = category ? "category=" + category : "";
	const pageQuery = page ? "&page=" + page : "";
	const searchQuery = search ? "&search=" + search : "";
	const sortQuery = sort ? "&sort=" + sort : "";
	try {
		const response = await instance.get(
			"products?" + categoryQuery + pageQuery + searchQuery + sortQuery,
			{
				headers: {apiKey},
			}
		);
		return response.data;
	} catch (error) {
		if (error) {
			localStorage.removeItem("token");
			localStorage.removeItem("apiKey");
			window.location.reload();
		}
	}
};

export const getByIdProduct = async id => {
	const apiKey = localStorage.getItem("apiKey");
	try {
		const response = await instance.get("products/" + id, {
			headers: {apiKey},
		});
		return response.data;
	} catch (error) {
		if (error) {
			localStorage.removeItem("token");
			localStorage.removeItem("apiKey");
			window.location.reload();
		}
	}
};

export const getAllCustomers = async ({page, search}) => {
	const token = localStorage.getItem("token");
	const pageQuery = page ? "&page=" + page : "";
	const searchQuery = search ? "&search=" + search : "";
	try {
		const response = await instance.get(
			"customer/getall?" + pageQuery + searchQuery,
			{
				headers: {Authorization: token ? "Bearer " + token : ""},
			}
		);
		return response.data;
	} catch (error) {
		if (error) {
			localStorage.removeItem("token");
			localStorage.removeItem("apiKey");
			window.location.reload();
		}
	}
};

export const getByIdCustomer = async id => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.get("customer/" + id, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (error) {
			localStorage.removeItem("token");
			localStorage.removeItem("apiKey");
			window.location.reload();
		}
	}
};

export const getAllOrders = async ({page, search, status, sort}) => {
	const token = localStorage.getItem("token");
	const statusQuery = status ? "status=" + status : "";
	const pageQuery = page ? "&page=" + page : "";
	const searchQuery = search ? "&search=" + search : "";
	const sortQuery = sort ? "&sort=" + sort : "";
	try {
		const response = await instance.get(
			"customer/allorder?" + statusQuery + pageQuery + searchQuery + sortQuery,
			{
				headers: {Authorization: token ? "Bearer " + token : ""},
			}
		);
		return response.data;
	} catch (error) {
		if (error) {
			localStorage.removeItem("token");
			localStorage.removeItem("apiKey");
			window.location.reload();
		}
	}
};

export const getByIdOrder = async id => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.get("customer/order/" + id, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const createProduct = async form => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.post("products", form, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const updateProduct = async (id, form) => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.put("products/" + id, form, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const deleteProduct = async id => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.delete("products/" + id, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const getUserProfile = async () => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.get("auth/profile", {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (error) {
			localStorage.removeItem("token");
			localStorage.removeItem("apiKey");
			window.location.reload();
		}
	}
};

export const updateProfile = async body => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.put("auth/update", body, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const updatePassword = async body => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.put("auth/updatepassword", body, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const getAllUsers = async () => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.get("auth/getall", {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const deleteUser = async id => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.delete("auth/delete/" + id, {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const editRoleUser = async (id, role) => {
	const token = localStorage.getItem("token");
	try {
		const response = await instance.put(
			"auth/updaterole/" + id,
			{role},
			{
				headers: {Authorization: token ? "Bearer " + token : ""},
			}
		);
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const login = async (email, password) => {
	try {
		const response = await instance.post("auth/login", {email, password});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const logout = () => {
	localStorage.removeItem("token");
	localStorage.removeItem("apiKey");
	window.location.reload();
};

export const register = async (
	name,
	email,
	password,
	phone,
	registerAdminKey
) => {
	try {
		const response = await instance.post("auth/register", {
			name,
			email,
			password,
			phone,
			registerAdminKey,
		});
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};

export const deleteCustomerAccount = async (phone, password) => {
	try {
		const user = await instance.post("/customer/login", {phone, password});
		const token = user?.data?.token;
		console.log(user, token);
		const response = await instance.delete("customer/delete", {
			headers: {Authorization: token ? "Bearer " + token : ""},
		});
		console.log(response);
		return response.data;
	} catch (error) {
		if (
			error &&
			error.response &&
			error.response.data &&
			error.response.data.message
		)
			toast.error(error.response.data.message);
	}
};
