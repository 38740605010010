import {Link} from "react-router-dom";
// reactstrap components
import {NavbarBrand, Navbar, Container} from "reactstrap";

const PublicNavbar = () => {
	return (
		<>
			<Navbar className="navbar-top navbar-horizontal navbar-dark" expand="md">
				<Container className="px-4">
					<NavbarBrand to="/" tag={Link}>
						<img
							alt="..."
							style={{borderRadius: "50%"}}
							src={require("../../assets/logo/logo.png")}
						/>
						<span> Xalqlar do'stligi</span>
					</NavbarBrand>
				</Container>
			</Navbar>
		</>
	);
};

export default PublicNavbar;
