import PublicNavbar from "components/Navbars/PublicNavbar";
import {deleteCustomerAccount} from "network/ApiAxios";
import React from "react";
import {toast} from "react-toastify";
import {
	Button,
	Card,
	Col,
	Container,
	Modal,
	Row,
	Form,
	FormGroup,
	Input,
	ModalHeader,
} from "reactstrap";

const DeleteAccount = () => {
	const [modal, setModal] = React.useState(false);
	const [phone, setPhone] = React.useState("");
	const [password, setPassword] = React.useState("");
	React.useEffect(() => {
		document.body.classList.add("bg-default");
		return () => {
			document.body.classList.remove("bg-default");
		};
	}, []);

	const handleDelete = async () => {
		const data = await deleteCustomerAccount(phone, password);
		if (data && data.success) {
			toast.success("Hisobingiz muvaffaqiyatli o'chirildi");
			toggle();
		}
	};

	const toggle = () => {
		setPhone("");
		setPassword("");

		setModal(!modal);
	};
	return (
		<div className="main-content">
			<PublicNavbar />
			<div className="header bg-gradient-info py-7 py-lg-8">
				<div className="separator separator-bottom separator-skew zindex-100">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						preserveAspectRatio="none"
						version="1.1"
						viewBox="0 0 2560 100"
						x="0"
						y="0"
					>
						<polygon className="fill-default" points="2560 0 2560 100 0 100" />
					</svg>
				</div>
			</div>
			<Container className="mt--8 pb-5">
				<Row className="justify-content-center">
					<Col lg="12" md="12">
						<Card className="bg-secondary border-0 p-4 py-5">
							<Col lg="12" md="12">
								<Card
									className="border-0 p-4"
									style={{backgroundColor: "#fae9ea"}}
								>
									<Row className="px-4">
										<svg
											fill="#dc3545"
											width="24"
											height="24"
											viewBox="0 0 32 32"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path d="M 16 4 C 9.382813 4 4 9.382813 4 16 C 4 22.617188 9.382813 28 16 28 C 22.617188 28 28 22.617188 28 16 C 28 9.382813 22.617188 4 16 4 Z M 16 6 C 21.535156 6 26 10.464844 26 16 C 26 21.535156 21.535156 26 16 26 C 10.464844 26 6 21.535156 6 16 C 6 10.464844 10.464844 6 16 6 Z M 15 10 L 15 18 L 17 18 L 17 10 Z M 15 20 L 15 22 L 17 22 L 17 20 Z" />
										</svg>
										<p className="text-red ml-2">Ogohlantirish</p>
									</Row>
									<p>
										Xalqlar do'stligi akkauntingizni o'chirish quyidagi bilan
										xayrlashishni anglatadi:
									</p>
									<ul>
										<li>
											Buyurtma tarixi: Buyurtmalaringiz tarixi o'chiriladi
										</li>
										<li>
											Profil ma'lumotlari va manzillar: Bir nechta yetkazib
											berish manzillarini yaratdingizmi? Poof! Ular sizning
											hisobingiz bilan yo'qoladi.
										</li>
									</ul>

									<p>
										Hali ham oʻchirib tashlamoqchimisiz? Biz tushunamiz.
										Ishonchingiz komil bo'lsa, davom eting. Ammo agar sizda
										biron bir savol bo'lsa yoki hisobingiz holati haqida
										gaplashmoqchi bo'lsangiz, bizning do'stona Xalqlar do'stligi
										qo'llab-quvvatlash jamoasi yordam berishdan doimo xursand
										bo'ladi!
									</p>
									<a href="mailto:abdulkhaev96@gmail.com">
										abdulkhaev96@gmail.com
									</a>
								</Card>
								<Button
									className="mt-5 btn btn-outline-danger"
									onClick={toggle}
								>
									Hisobni o'chirish
								</Button>
							</Col>
						</Card>
					</Col>
				</Row>
			</Container>
			<Modal isOpen={modal} toggle={toggle} centered>
				<ModalHeader toggle={toggle}>Hisobni o'chirish</ModalHeader>
				<Card className="border-0 p-5">
					<Form role="form">
						<FormGroup className="mb-3">
							<Input
								placeholder="Akkaunt telefon raqamini kiriting"
								type="tel"
								autoComplete="tel"
								value={phone}
								onChange={e => setPhone(e.target.value)}
							/>
						</FormGroup>
						<FormGroup>
							<Input
								placeholder="Akkaunt parolini kiriting"
								type="password"
								autoComplete="password"
								value={password}
								onChange={e => setPassword(e.target.value)}
							/>
						</FormGroup>
						<div className="text-center">
							<Button
								className="my-4 btn btn-danger"
								type="button"
								onClick={handleDelete}
							>
								O'chirish
							</Button>
						</div>
					</Form>
				</Card>
			</Modal>
		</div>
	);
};

export default DeleteAccount;
