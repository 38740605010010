import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Switch, Redirect, Route} from "react-router-dom";

import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import AuthRoutes from "./components/PrivateRoute/AuthRoutes";
import Privacy from "views/examples/Privacy.js";
import DeleteAccount from "views/examples/DeleteAccount.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<BrowserRouter>
		<ToastContainer />
		<Switch>
			<PrivateRoute path="/admin" component={AdminLayout} />
			<AuthRoutes path="/auth" component={AuthLayout} />
			<Route path="/privacy" component={Privacy} />
			<Route path="/delete-account" component={DeleteAccount} />
			<Redirect from="/" to="/admin/index" />
		</Switch>
	</BrowserRouter>
);
