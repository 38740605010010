import React, { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import { useParams } from "react-router-dom";
import { getByIdOrder } from "../../network/ApiAxios";
import { Container } from "reactstrap";

const OneOrder = (props) => {
  const { id } = useParams();
  const [order, setOrder] = useState("");
  const {
    status,
    orderDate,
    customer,
    products,
    address,
    orderNumber,
    order_receipt,
  } = order;

  useEffect(() => {
    const getOrder = async () => {
      const data = await getByIdOrder(id);
      if (data && data.data) {
        setOrder(data.data);
      } else {
        props.history.push("/");
      }
    };
    getOrder();
  }, [id, props.history]);

  return (
    <div>
      <Header />
      <Container className="mt-4">
        <h2 className="bg-light text-center p-1">Buyurtma haqida</h2>
        <h2 className="mt-3">
          Buyurtmachi telefon raqami: {customer && customer.phone}
        </h2>
        <h2>Buyurtma statusi: {status}</h2>
        <h2>Buyurtma raqami: {orderNumber}</h2>
        <h2>Buyurtma sanasi: {orderDate}</h2>
        <br />
        <h2>Manzil: {address && address.address}</h2>
        <h2>Qabul qiluvchining ismi: {address && address.recipientName}</h2>
        <h2>Qabul qiluvchining raqami: {address && address.recipientPhone}</h2>
        <h2>Xona va bino raqami: {address && address.roomAndBuildingNumber}</h2>
        <h2>
          Qoldirish joyi:{" "}
          {address && address.placeOfAbandonment === "door"
            ? "Eshik oldida"
            : address && address.placeOfAbandonment === "guard"
            ? "Qorovulxonada"
            : "Boshqa joyda"}
        </h2>
        <h2>Eshik kodi: {address && address.doorCode}</h2>

        <h2>
          Buyurtma cheki:{" "}
          <img
            style={{ height: "350px" }}
            className="img-fluid img-thumbnail"
            src={order_receipt}
            alt={""}
          />
        </h2>

        {order && products.length > 0 ? (
          <div>
            <h2 className="bg-green text-center text-white p-1">
              Buyurtmadagi mahsulotlari
            </h2>
            <ol>
              {order &&
                products.map((item) => (
                  <li key={item._id}>
                    <b>Mahsulot</b>
                    <ul>
                      <li>
                        Buyurtma miqdori: {item.amount}{" "}
                        {item.product.unitOfAmount}
                      </li>
                      <li>Mahsulot nomi: {item.product.productName}</li>
                      <li>Bar code: {item.product.barCode}</li>
                      <li>
                        Sotilgan narxi (1 miqdori): {item.product.sellingPrice}
                      </li>
                      <li>Yaroqlilik muddati: {item.product.expirationDate}</li>
                      <li>
                        Mahsulot rasmi{" "}
                        <img
                          style={{ height: "150px" }}
                          className="img-fluid img-thumbnail"
                          src={`https://s3.timeweb.cloud/1a12bb9e-files-db/xalqlar-dustligi/${item.product.imageProduct}`}
                          alt={item.product.productName}
                        />
                      </li>
                    </ul>
                  </li>
                ))}
            </ol>
          </div>
        ) : (
          <h2>Mahsulot negadir yo'q qandaydir xatolik bo'lgan!</h2>
        )}
      </Container>
    </div>
  );
};

export default OneOrder;
