import { useEffect, useState } from "react";
import Header from "components/Headers/Header";
import {
  Button,
  Container,
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  getUserProfile,
  getAllUsers,
  deleteUser,
  editRoleUser,
} from "../../network/ApiAxios";
import { toast } from "react-toastify";

const Settings = () => {
  const [user, setUser] = useState({});
  const [usersAll, setUsersAll] = useState([]);
  const [show, setShow] = useState(false);
  const [showE, setShowE] = useState(false);
  const [currentUser, setCurrentUser] = useState("");
  const [roleStatus, setRoleStatus] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);

  const getUsersAll = async () => {
    const data = await getAllUsers();
    if (data && data.success) setUsersAll(data.data);
  };

  const deleteUsers = async () => {
    handleToggle();
    try {
      const data = await deleteUser(currentUser._id);
      getUsersAll();
      if (data && data.success) toast.success(data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const deleteClick = (user) => {
    setCurrentUser(user);
    handleModalShow();
  };

  const handleModalShowE = () => {
    setShowE(true);
  };
  const handleToggleE = () => {
    setShowE(!showE);
  };

  const handleModalShow = () => {
    setShow(true);
  };
  const handleToggle = () => {
    setShow(!show);
  };

  const clickedEdit = (user) => {
    handleModalShowE();
    setCurrentUser(user);
  };

  const editRoleStatus = async () => {
    const data = await editRoleUser(currentUser._id, roleStatus);
    getUsersAll();
    handleToggleE();
    if (data && data.success) toast.success(data.message);
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await getUserProfile();
      if (data && data.success && data.data) setUser(data.data);
    };

    getUser();

    getUsersAll();
  }, []);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        setBtnDisabled(false);
      }, 2500);
    } else {
      setBtnDisabled(true);
    }
  }, [show, setBtnDisabled]);

  return (
    <div>
      <Header />
      {user && user.role === "superAdmin" ? (
        <>
          <Container>
            {usersAll.length > 0 ? (
              <Table className="mt-4">
                <thead>
                  <tr>
                    <td>T/r</td>
                    <td>Name</td>
                    <td>Email</td>
                    <td>Phone</td>
                    <td>Role</td>
                    <td>Edit</td>
                    <td>Delete</td>
                  </tr>
                </thead>
                <tbody>
                  {usersAll.map((user, index) => (
                    <tr key={user._id}>
                      <td>{index + 1}</td>
                      <td>{user.name}</td>
                      <td>{user.email}</td>
                      <td>{user.phone}</td>
                      <td>{user.role}</td>
                      <td>
                        <Button
                          color="info"
                          className="btn-sm"
                          onClick={() => clickedEdit(user)}
                        >
                          Edit
                        </Button>
                      </td>
                      <td>
                        <Button
                          color="danger"
                          className="btn-sm"
                          onClick={() => deleteClick(user)}
                        >
                          Del
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <h1 className="mt-4">
                Sizdan boshqa hech kim hali ro'yxatdan o'tmagan!
              </h1>
            )}
          </Container>

          <Modal isOpen={showE} toggle={handleToggleE} centered={true}>
            <ModalHeader tag={"h2"}>
              <b>{currentUser.name}</b>ning {currentUser.role} huquqini
              o'zgartirish
            </ModalHeader>
            <ModalBody>
              <select
                name="roleStatus"
                onChange={(e) => {
                  setRoleStatus(e.target.value);
                }}
                defaultValue={currentUser.role}
                className="form-control"
              >
                <option value="admin">admin</option>
                <option value="middleAdmin">middleAdmin</option>
              </select>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleToggleE}>
                Bekor qilish
              </Button>
              <Button className="btn-danger" onClick={editRoleStatus}>
                O'zgartirish
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={show} toggle={handleToggle} centered={true}>
            <ModalBody className="mt-2">
              Siz haqiqatdan ham <b>{currentUser.name}</b>ni o'chirmoqchimisiz?
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={handleToggle}>
                Bekor qilish
              </Button>
              <Button
                className="btn-danger"
                disabled={btnDisabled}
                onClick={() => deleteUsers()}
              >
                O'chirish
              </Button>
            </ModalFooter>
          </Modal>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default Settings;
