import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Col,
  Container,
  Row,
  Table,
  Button,
  FormGroup,
  Input,
} from "reactstrap";

import { getAllCustomers } from "../../network/ApiAxios";
import ProductPagination from "../../views/pagination/productPagination";

const CustomerTable = () => {
  const [customers, setCustomer] = useState([]);
  const [pageData, setPageData] = useState({});
  const [searchText, setSearchText] = useState("");

  const getAll = async (page = 1, search = searchText) => {
    const data = await getAllCustomers({ page, search });
    if (data && data.success && data.data) {
      setCustomer(data.data);
      setPageData({
        pageCount: data.pageCount,
        currentPage: data.currentPage,
        nextPage: data.nextPage,
        allCount: data.allCustomersCount,
      });
    }
  };

  const onSubmitSearch = (e) => {
    e.preventDefault();
    getAll(1, searchText);
  };

  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, [searchText]);

  return (
    <div className="mt-4">
      <Container>
        <Row>
          <Col md="4" className=" offset-md-2">
            <form onSubmit={onSubmitSearch} id="searchSubmit">
              <FormGroup>
                <Input
                  placeholder="Qiriruv..."
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </FormGroup>
            </form>
          </Col>
          <Col md="4">
            <h1>Barcha xaridorlar {pageData.allCount} ta</h1>
          </Col>
        </Row>
        {customers.length > 0 ? (
          <Row>
            <Col md="6" className=" offset-md-3">
              <Table>
                <thead>
                  <tr>
                    <td>ID</td>
                    <td>Telefon</td>
                    <td>Buyurtma</td>
                    <td>Ma'lumot</td>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer) => (
                    <tr key={customer._id}>
                      <td>{customer._id.slice(21)}</td>
                      <td>{customer.phone}</td>
                      <td>{customer.orders.length}</td>
                      <td>
                        <Link to={"/admin/customer/" + customer._id}>
                          <Button color="info" className="btn-sm">
                            I
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        ) : (
          <h1 className="mt-4">Xaridor mavjud emas!</h1>
        )}
        {pageData.pageCount > 1 ? (
          <ProductPagination getAll={getAll} pageData={pageData} />
        ) : (
          ""
        )}
      </Container>
    </div>
  );
};

export default CustomerTable;
